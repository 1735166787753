<template lang="pug">
section.section.import-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .columns(v-if="!initializing")
    .column.is-12
      h1.title Import
  b-modal(:active.sync="showNewAssetCategory" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    new-asset-dialog(@done="setCategory(newCategoryContext, $event)" :initial-name="newCategoryContext && newCategoryContext.name")
  b-modal(:active.sync="showNewLiabilityCategory" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    new-liability-dialog(@done="setCategory(newCategoryContext, $event)" :initial-name="newCategoryContext && newCategoryContext.name")
  .columns(v-if="!initializing")
    .column.is-12
      upgrade-prompt(v-if="isFreePlan && atFreePlanLimit" limit-reached='entries')
      .box
        b-steps(v-model="activeStep" :animated="true" :has-navigation="false")
          b-step-item(label="Upload Spreadsheet" :clickable="true")
            b-message(type="is-info")
              p
                strong How to import your data:
              p.content
                | Prepare a spreadsheet in xlsx (excel) format where:
                ul
                  li The first column in each sheet contains the date of the entry in YYYY-MM-DD format (e.g 2020-01-01).
                  li Each other column in that sheet represents the value of a particular asset or liability.
                  li The first row in each sheet is a header row with labels for the assets/liabilities.
            strong Upload your .xlsx spreadsheet here:
            br
            b-upload(v-model="attachedFile" drag-drop accept=".xlsx")
              section.section
                .content.has-text-centered
                  p
                    b-icon(icon="upload" size="is-large")
                  p Drop your files here or click to upload

          b-step-item(label="Choose Data" :clickable="!!sheets")
            b-message(type="is-info")
              strong Choose a category for each column in your spreadsheet
              p
                | Each sheet inside your file has a tab below that you can
                | configure.
              p
                | For each column you want to import, choose an asset or liability
                | inside nutworth to import into.
              p
                | You can then choose the currency used in that column, in
                | case your spreadsheet uses mixed currencies.
            b-tabs(v-model="activeSheet")
              b-tab-item(:label="sheet.name" v-for="sheet in sheets" :key="sheet.name")
                .sheet-column(v-for="col in sheet.cols" :key="col.name")
                  .column
                    .column-behavior
                      .column-name {{col.name}}
                      b-field
                        b-radio-button(:value="col.type" @input="setColType(col, $event)" native-value="skip")
                          span Ignore
                        b-radio-button(:value="col.type" @input="setColType(col, $event)" native-value="asset")
                          span Asset
                        b-radio-button(:value="col.type" @input="setColType(col, $event)" native-value="liability")
                          span Liability
                    .column-category(v-if="col.type !== 'skip'")
                      strong.category-heading Import as
                      .chosen-category
                      .field.has-addons(v-if="col.category")
                        p.control
                          a.button.category-name {{ col.category.name }}
                        p.control
                          b-button.is-danger(icon-left="times" @click="removeCategory(col)" title="Remove category")
                      multi-select(:value="null" @input="setCategory(col, $event)" :options="addableAssets" label="name" track-by="id" placeholder="Asset type..." v-if="col.type == 'asset' && !col.category")
                      multi-select(:value="null" @input="setCategory(col, $event)" :options="addableLiabilities" label="name" track-by="id" placeholder="Liability type..." v-if="col.type == 'liability' && !col.category")
                    .column-currency(v-if="col.type !== 'skip'")
                      b-field.currency-picker(label="Currency")
                        currency-select(:value="col.currency" @input="updateCurrency(col, $event)" placeholder="Set Currency" prefix="")
                    .column-subunit(v-if="col.type !== 'skip' && hasSubunits(col.currency)")
                      b-field.subunit-picker(label="Unit")
                        subunit-select(v-model="col.subunit" :currency="col.currency.id")
                b-button(type="is-large is-primary" @click="activeStep += 1" :disabled="formInvalid") Done

          b-step-item(label="Finish" :clickable="!formInvalid")
            p.behavior-choice
              strong Choose behavior for when you have existing data:
            b-field
              b-radio-button(v-model="conflictStrategy" native-value="skip")
                span Skip row
              b-radio-button(v-model="conflictStrategy" native-value="overwrite")
                span Overwrite
              b-radio-button(v-model="conflictStrategy" native-value="merge")
                span Merge
            p
              strong Log
            pre {{ log }}
            .buttons
              b-button(type="is-large is-primary" :loading="importing" @click="startImport" :disabled="formInvalid") Import!
              span(v-if="status") {{ status }}

</template>
<script>
import { find, filter } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'

import xlsxParser from '@/misc/async-xlsx'
import firestore from '@/firebase/async-firestore'
import NewAssetDialog from '@/components/NewAssetDialog.vue'
import NewLiabilityDialog from '@/components/NewLiabilityDialog.vue'
import UpgradePrompt from '@/components/UpgradePrompt.vue'
import CurrencySelect from '@/components/CurrencySelect'
import SubunitSelect from '@/components/SubunitSelect'
import { prepareEntry, currencyWithSubunit } from '@/misc/helpers'

const maxEntries = 100
const batchSize = 50

export default {
  name: 'ImportView',
  components: {
    NewAssetDialog,
    NewLiabilityDialog,
    UpgradePrompt,
    CurrencySelect,
    SubunitSelect
  },
  data() {
    return {
      activeStep: 0,
      activeSheet: undefined,
      importing: false,
      conflictStrategy: 'skip',
      attachedFile: null,
      sheets: null,
      showNewLiabilityCategory: false,
      showNewAssetCategory: false,
      newCategoryContext: null,
      addableAssets: [],
      addableLiabilities: [],
      status: null,
      log: 'Ready!'
    }
  },
  computed: {
    ...mapState('currencies', ['activeCurrency', 'loadingCurrencies']),
    ...mapState('categories', ['loadingCategories']),
    ...mapGetters('categories', ['orderedCategories']),
    ...mapGetters('currencies', ['activeSubunit']),
    ...mapGetters('authentication', ['isFreePlan']),
    ...mapGetters('entries', ['atFreePlanLimit']),
    initializing() {
      return this.loadingCurrencies || this.loadingCategories
    },
    formInvalid() {
      let atLeastOneMapping = false
      // No sheet data yet
      if (!this.sheets || !this.sheets.length) return true
      for (let sheetIdx = 0; sheetIdx < this.sheets.length; ++sheetIdx) {
        const sheet = this.sheets[sheetIdx]
        for (let colIdx = 0; colIdx < sheet.cols.length; ++colIdx) {
          const col = sheet.cols[colIdx]
          if (col.type === 'skip') continue
          atLeastOneMapping = true
          // A column without a mapping
          if (!col.category) return true
        }
      }
      // No mappings, nothing to import
      if (!atLeastOneMapping) return true
      // Should be okay
      return false
    }
  },
  watch: {
    attachedFile(file) {
      if (!file) return
      xlsxParser()
        .then(
          XLSX =>
            new Promise((res, rej) => {
              const fileReader = new FileReader()
              fileReader.onload = fileData => {
                try {
                  const bytes = new Uint8Array(fileData.target.result)
                  res(
                    XLSX.read(bytes, {
                      type: 'array',
                      cellDates: true
                    })
                  )
                } catch (e) {
                  rej(e)
                }
              }
              fileReader.readAsArrayBuffer(file)
            })
        )
        .then(workbook => this.parseSpreadsheet(workbook))
        .then(() => {
          this.activeStep = 1
          this.activeSheet = undefined
        })
    }
  },
  mounted() {
    // Trigger the lazy load
    xlsxParser()
  },
  methods: {
    ...mapActions('currencies', ['setDisplayCurrency', 'getExchangeRateOn']),
    ...mapActions('authentication', ['updateReminderState']),
    startImport() {
      const log = new Set()
      this.importing = true
      this.log = null
      setTimeout(async () => {
        try {
          const XLSX = await xlsxParser()
          // ISO Date -> entry
          const entries = {}
          const promises = []
          this.sheets.forEach(sheetMapping => {
            sheetMapping.cols.forEach(colMapping => {
              if (colMapping.type === 'skip') return
              const sheet = this.workbook.Sheets[sheetMapping.name]
              const range = XLSX.utils.decode_range(sheet['!ref'])
              const { colNum } = colMapping
              const colName = colMapping.name
              // Skip header row
              for (let rowNum = range.s.r + 1; rowNum <= range.e.r; ++rowNum) {
                const dateCell =
                  sheet[XLSX.utils.encode_cell({ r: rowNum, c: range.s.c })]
                const valueCell =
                  sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })]
                // Skip blank cells
                if (
                  typeof valueCell === 'undefined' ||
                  typeof dateCell === 'undefined'
                )
                  continue
                const date = dateCell.v && dayjs(dateCell.v)
                const value = valueCell.v
                if (typeof value !== 'number') {
                  log.add(
                    `Skipping row ${rowNum +
                      1} due to invalid number ${JSON.stringify(value)}"`
                  )
                  continue
                }
                // Skip those with invalid dates in first column
                if (!date || !date.isValid()) {
                  log.add(
                    `Skipping row ${rowNum +
                      1} due to invalid date ${JSON.stringify(dateCell.w)}"`
                  )
                  continue
                }
                // Lazy init an entry for it
                const dateRef = date.format('YYYY-MM-DD')
                if (!(dateRef in entries)) {
                  entries[dateRef] = {
                    date: dayjs(dateRef).toDate(),
                    assets: [],
                    liabilities: [],
                    note: ''
                  }
                }
                // Write an asset or liability
                let ref
                if (colMapping.type === 'asset') {
                  ref = entries[dateRef].assets
                } else {
                  ref = entries[dateRef].liabilities
                }
                const obj = {
                  amount: value,
                  baseRate: 1,
                  categoryId: colMapping.category.id,
                  currency: colMapping.currency.id,
                  subunit: colMapping.subunit,
                  type: colMapping.category.type,
                  customRate: false
                }
                const promise = this.getExchangeRateOn({
                  date: date.toDate(),
                  currency: currencyWithSubunit(obj.currency, obj.subunit)
                })
                  .then(rate => {
                    obj.baseRate = rate
                    // If we already have something for that category being imported to that day, skip it
                    if (
                      find(
                        ref,
                        ({ categoryId }) => categoryId === obj.categoryId
                      )
                    ) {
                      log.add(
                        `Skipping row ${rowNum +
                          1} column ${colName} due to data duplication`
                      )
                      return
                    }
                    ref.push(obj)
                  })
                  .catch(() =>
                    log.add(
                      `Could not get ${obj.currency} exchange rate on ${dateRef}, skipping`
                    )
                  )
                promises.push(promise)
              }
            })
          })
          this.status = 'Converting currencies...'
          await Promise.all(promises)
          this.status = 'Saving entries...'
          await this.saveAllEntries(entries, log)
          this.status = 'Imported successfully!'
          log.add('Done!')
        } catch (error) {
          this.$buefy.dialog.alert({
            title: 'An error occurred',
            message: error.message
          })
        }
        this.importing = false
        this.log = Array.from(log).join('\n')
      }, 50)
    },
    async saveAllEntries(entries, log) {
      const promises = []
      const db = await firestore()
      const { isFreePlan } = this
      let batch = db.batch()
      let batchCtr = 0
      let entryCtr = this.$store.getters['entries/totalEntries']
      let latestDate = null
      Object.values(entries).forEach(entry => {
        entry.id = dayjs(entry.date).format('YYYY-MM-DD')
        if (!latestDate || latestDate < entry.id) {
          latestDate = entry.id
        }
        const existingEntry = this.$store.getters['entries/getEntryById'](
          entry.id
        )
        const ref = db
          .collection('users')
          .doc(this.$store.state.authentication.user.id)
          .collection('entries')
          .doc(entry.id)
        if (existingEntry) {
          if (this.conflictStrategy === 'skip') {
            log.add(
              `Skipping ${dayjs(entry.date).format(
                'YYYY-MM-DD'
              )} due to existing entry`
            )
            return
          }
          if (this.conflictStrategy === 'overwrite') {
            batch.set(ref, prepareEntry(entry))
            batchCtr += 1
          } else {
            // Merge entries
            entry.note = existingEntry.note
            existingEntry.assets.forEach(asset => {
              if (
                find(
                  entry.assets,
                  ({ categoryId }) => asset.categoryId === categoryId
                )
              ) {
                return
              }
              entry.assets.push(asset)
            })
            existingEntry.liabilities.forEach(liability => {
              if (
                find(
                  entry.liabilities,
                  ({ categoryId }) => liability.categoryId === categoryId
                )
              ) {
                return
              }
              entry.liabilities.push(liability)
            })
            batch.set(ref, prepareEntry(entry))
            batchCtr += 1
          }
        } else {
          if (isFreePlan && entryCtr > maxEntries) {
            log.add(
              `Skipping ${dayjs(entry.date).format(
                'YYYY-MM-DD'
              )} due to free plan limitations (max 100 entries)`
            )
            return
          }
          batch.set(ref, prepareEntry(entry))
          entryCtr += 1
          batchCtr += 1
        }
        if (batchCtr >= batchSize) {
          promises.push(batch.commit())
          batch = db.batch()
          batchCtr = 0
        }
      })
      if (batchCtr) promises.push(batch.commit())
      await Promise.all(promises)
      if (latestDate) {
        this.updateReminderState(latestDate)
      }
    },
    updateCurrency(col, currency) {
      col.currency = currency
      col.subunit = null
    },
    async parseSpreadsheet(workbook) {
      const XLSX = await xlsxParser()
      const sheets = []
      this.workbook = workbook
      workbook.SheetNames.forEach(sheetName => {
        const sheet = workbook.Sheets[sheetName]

        if (!sheet['!ref']) return

        const range = XLSX.utils.decode_range(sheet['!ref'])
        const sheetObject = { name: sheetName, cols: [] }

        // + 1 to skip the date column
        for (let colNum = range.s.c + 1; colNum <= range.e.c; ++colNum) {
          const cell =
            sheet[XLSX.utils.encode_cell({ r: range.s.r, c: colNum })]
          if (typeof cell !== 'undefined') {
            sheetObject.cols.push({
              name: cell.w,
              colNum,
              type: 'skip',
              category: null,
              currency: this.activeCurrency,
              subunit: this.activeSubunit ? this.activeSubunit.id : null
            })
          }
        }
        sheets.push(sheetObject)
      })
      this.sheets = sheets
      this.computeAddableAssets()
    },
    setColType(col, type) {
      col.type = type
      col.category = null
    },
    setCategory(col, category) {
      if (category.id === 'new') {
        this.newCategoryContext = col
        if (col.type === 'asset') {
          this.showNewAssetCategory = true
        } else {
          this.showNewLiabilityCategory = true
        }
        return
      }
      this.newCategoryContext = null
      this.showNewAssetCategory = false
      this.showNewLiabilityCategory = false
      col.category = category
      this.computeAddableAssets()
    },
    removeCategory(col) {
      col.category = null
      this.computeAddableAssets()
    },
    hasSubunits(currency) {
      if (currency) {
        return 'subunits' in currency && Object.values(currency.subunits).length
      }
      return false
    },
    computeAddableAssets() {
      const assets = []
      const liabilities = []
      const categories = filter(
        this.orderedCategories,
        category =>
          !find(this.sheets, sheet =>
            find(
              sheet.cols,
              col => col.category && col.category.id === category.id
            )
          )
      )
      categories.forEach(category => {
        if (category.isAsset) assets.push(category)
        else liabilities.push(category)
      })
      assets.push({ name: 'Add new category...', id: 'new' })
      liabilities.push({ name: 'Add new category...', id: 'new' })
      this.addableAssets = assets
      this.addableLiabilities = liabilities
    }
  },
  metaInfo: {
    title: 'Import Data'
  }
}
</script>
<style lang="sass" scoped>
.title
  margin-top: 2.5rem
.sheet-column .column
  display: flex
  flex-direction: row
  flex-wrap: wrap
.column-name
  font-weight: bold
  padding-bottom: 0.5rem
.category-heading
  margin-bottom: 0.5rem
  display: block
.currency-picker
  width: 250px
.column-category
  min-width: 250px
  width: 100%
  max-width: 350px
  .control:first-child
    flex-grow: 1
  .category-name
    width: 100%
    justify-content: flex-start
    cursor: default
.sheet-column > .column > div
  margin-bottom: 0.75rem
  margin-right: 0.5rem
  &:last-child
    margin-right: 0
pre
  height: 300px
  overflow-y: scroll
  margin: 1rem 0
.behavior-choice
  margin-top: 1rem
  margin-bottom: 0.5rem
.section
  padding: 1rem 0.5rem
::v-deep
  .import-screen .step-content
    padding: 1rem 0.25rem !important
</style>
