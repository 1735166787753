import { sortBy } from 'lodash'
import {
  currencyInputFormatter,
  currencyWithSubunit,
  getPreferences
} from '@/misc/helpers'

export default {
  computed: {
    subunits() {
      const { chosenCurrency } = this
      if (!this.chosenCurrency) {
        return null
      }
      const { subunits = null } = chosenCurrency
      return subunits
    },
    orderedSubunits() {
      if (!this.subunits) {
        return []
      }
      return sortBy(Object.values(this.subunits), 'order')
    },
    hasSubunits() {
      const { subunits } = this
      return subunits && Object.values(subunits).length
    },
    defaultSubunit() {
      const { subunits } = this
      if (!subunits) {
        return null
      }
      return Object.values(subunits).find(({ isDefault }) => isDefault) || null
    },
    chosenSubunit() {
      const { subunit, subunits, defaultSubunit } = this
      if (!subunits) {
        return null
      }
      if (subunit && subunit in subunits) {
        return subunits[subunit]
      }
      return defaultSubunit
    },
    currencyOption() {
      if (!this.chosenCurrency) {
        return undefined
      }
      return currencyInputFormatter(this.chosenCurrency, this.chosenSubunit)
    },
    currencyPrecision() {
      const { chosenCurrency, subunit } = this
      const { useDecimals } = getPreferences(this.$store.state)
      if (!chosenCurrency) {
        return undefined
      }
      if (chosenCurrency.type === 'fiat') {
        if (useDecimals) {
          return undefined
        }
        return { min: 0, max: 0 }
      }
      const { minPrecision, maxPrecision } = this.$store.getters[
        'currencies/currencyFormatOptions'
      ](currencyWithSubunit(chosenCurrency.id, subunit))
      return { min: minPrecision, max: maxPrecision }
    }
  }
}
