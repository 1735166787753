<template lang="pug">
b-select(:value="subunit" @input="$emit('input', $event)" :expanded="expanded")
  option(v-for="subunit in orderedSubunits" :value="subunit.id") {{ subunit.description }}
</template>

<script>
import subunits from '@/mixins/subunits'

export default {
  mixins: [subunits],
  props: {
    value: String,
    currency: String,
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chosenCurrency() {
      return this.$store.getters['currencies/getCurrencyById'](this.currency)
    },
    subunit() {
      const { value, defaultSubunit, chosenCurrency } = this
      if (value in chosenCurrency.subunits) {
        return value
      }
      if (defaultSubunit) {
        return defaultSubunit.id
      }
      return null
    }
  },
  mounted() {
    const { value, subunit } = this
    if (value !== subunit) {
      this.$emit('input', subunit)
    }
  }
}
</script>
