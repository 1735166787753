<template lang="pug">
multi-select(:value="value" :options="currencies" @input="onInput" :allow-empty="false" :custom-label="formatLabel" track-by="id" :placeholder="placeholder" deselect-label="Selected" select-label="Select" :tabindex="tabindex")
  template(slot="singleLabel" slot-scope="{ option }")
    span.prefix {{ prefix }}
    strong.option-id {{ optionName(option) }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Object,
    prefix: String,
    placeholder: String,
    tabindex: {
      type: Number,
      optional: true
    }
  },
  computed: {
    ...mapState('currencies', ['currencies'])
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    formatLabel(currency) {
      if (currency.type === 'fiat') {
        return currency.id
      }
      let shortName = currency.name.slice(0, 16)
      let currencyId = currency.id
      if (shortName !== currency.name) {
        shortName += '…'
      }
      if (currency.type === 'crypto') currencyId = currencyId.slice(1)
      return `${currencyId} - ${shortName}`
    },
    optionName(option) {
      if (option.type === 'crypto') return option.id.slice(1)
      return option.id
    }
  }
}
</script>

<style lang="sass" scoped>
.multiselect
  width: 100%
  max-width: 250px
  ::v-deep
    .multiselect__tags
      white-space: nowrap
    .multiselect__content-wrapper
      width: 300px
.option-id
  margin-left: 0.4rem
</style>
