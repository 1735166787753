<template lang="pug">
  b-message(title="At Plan Limit" type="is-warning" :closable="false")
    | You've reached the maximum number of {{limitReached}} allowed on our starter plan.
    | Thanks for trying us out! If you want to upgrade to allow unlimited
    | {{limitReached}},&nbsp;
    router-link(:to="{name: 'billing_plan'}") click here to choose your new plan.
</template>
<script>
export default {
  props: {
    limitReached: String
  }
}
</script>
