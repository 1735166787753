<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Add Asset Category
    b-button(icon-left="times" @click="$parent.close()")

  section.modal-card-body
    b-field(label="Name")
      b-input(type="text" v-model="form.name" required placeholder="e.g Savings Account" ref='nameField')
    .field
      label.label Asset Type
      .control.is-clearfix
        multi-select(:value="selectedAsset" :options="assetClasses" @input="setAssetClass" label="name" track-by="id" placeholder="Asset type e.g 'cash'")
      p.help Used to group together similar assets in charts
    b-field(label="Offset Account For" v-if="canHaveLoanAccount")
      b-select(v-model="form.offsetLoanId" placeholder="None")
        option(:value='null') None
        option(v-for="account in potentialLoanAccounts" :value="account.id") {{ account.name }}
    b-field(label="Custom Asset Name" v-if="form.type == 'custom'" message="Used to group your custom asset types in charts")
      b-input(type="text" v-model="form.customName" required placeholder="Custom asset name..." ref='customNameField')
    b-message(title="Error" type="is-warning" aria-close-label="Close" v-if="errorMessage") {{ errorMessage }}
    upgrade-prompt(v-if="atFreePlanLimit && isFreePlan" limit-reached='categories')
  footer.modal-card-foot
    b-button.is-primary(@click="save" :disabled="formInvalid || (atFreePlanLimit && isFreePlan)" :loading="isSaving") Add Category
</template>
<script>
import { find } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import UpgradePrompt from '@/components/UpgradePrompt'
import { assetClasses } from '@/misc/type_map'
import offsetLinker from '@/mixins/offsetLinker'

export default {
  components: { UpgradePrompt },
  mixins: [offsetLinker],
  props: {
    initialName: String
  },
  data() {
    return {
      form: {
        name: this.initialName || '',
        isAsset: true,
        type: null,
        customName: ''
      },
      assetClasses,
      isSaving: false,
      formInvalid: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapGetters('categories', ['atFreePlanLimit']),
    ...mapState('categories', ['categories']),
    ...mapGetters('authentication', ['isFreePlan']),
    selectedAsset() {
      if (!this.form.type) return undefined
      return find(assetClasses, asset => asset.id === this.form.type)
    }
  },
  watch: {
    form: {
      handler(form) {
        let isInvalid = false
        if (!form.type) {
          isInvalid = true
        }
        if (!form.name.length) {
          isInvalid = true
        }
        if (form.type === 'custom' && !form.customName.length) {
          isInvalid = true
        }
        this.formInvalid = isInvalid
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$refs.nameField.focus()
  },
  methods: {
    ...mapActions('categories', ['saveCategory']),
    setAssetClass(assetClass) {
      if (!assetClass) return
      this.form.type = assetClass.id
      if (assetClass.id === 'custom') {
        this.form.customName = this.form.name
        setTimeout(() => {
          this.$refs.customNameField.focus()
        }, 50)
      }
    },
    async save() {
      if (this.isSaving) return
      this.isSaving = true
      this.errorMessage = null
      try {
        const category = await this.saveCategory(this.form)
        this.$emit('done', category)
        this.$parent.close()
      } catch (error) {
        if (error.message === 'not-unique') {
          this.errorMessage =
            'You already have an asset or liability type named that. Please choose a new name'
        } else {
          this.errorMessage = error.message
        }
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-card, .modal-card-body
  overflow: visible
</style>
