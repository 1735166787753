import { isNil } from 'lodash'

let asyncXLSXParser = null

export default function() {
  if (isNil(asyncXLSXParser)) {
    asyncXLSXParser = import(/* webpackChunkName: "chunk-xlsx-parser" */ 'xlsx')
  }
  return asyncXLSXParser
}
