import Vue from 'vue'
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import { OffsetableLoanTypes, OffsetAccountTypes } from '@/misc/type_map'

export default {
  computed: {
    ...mapGetters('categories', ['orderedCategories']),
    potentialOffsetAccounts() {
      if (this.form.isAsset) {
        return []
      }
      return this.orderedCategories.filter(
        category =>
          category.isAsset && ~OffsetAccountTypes.indexOf(category.type)
      )
    },
    potentialLoanAccounts() {
      if (!this.form.isAsset) {
        return []
      }
      return this.orderedCategories.filter(
        category =>
          !category.isAsset && ~OffsetableLoanTypes.indexOf(category.type)
      )
    },
    canHaveOffsetAccount() {
      const { potentialOffsetAccounts, form } = this
      const { type } = form
      if (!potentialOffsetAccounts || !potentialOffsetAccounts.length) {
        return false
      }
      return ~OffsetableLoanTypes.indexOf(type)
    },
    canHaveLoanAccount() {
      const { potentialLoanAccounts, form } = this
      const { type } = form
      if (!potentialLoanAccounts || !potentialLoanAccounts.length) {
        return false
      }
      return ~OffsetAccountTypes.indexOf(type)
    },
    chosenOffsets() {
      const { potentialOffsetAccounts, form } = this
      return (form.offsetIds || [])
        .map(offsetId =>
          find(potentialOffsetAccounts, ({ id }) => id === offsetId)
        )
        .filter(x => !!x)
    }
  },
  methods: {
    updateOffsetIds(offsets) {
      Vue.set(
        this.form,
        'offsetIds',
        offsets.map(({ id }) => id)
      )
    }
  }
}
